import React from 'react'

const Divider = ({ rainbow, fullWidth, spacingLg }) => (
  <div className={fullWidth ? '' : 'container'}>
    <hr
      style={{ height: 1 }}
      className={`
        ${spacingLg ? 'spacing-lg' : 'no-margin'}
        ${rainbow ? 'rainbow' : ' '}
      `}
    />
  </div>
)

export default Divider
