import React from 'react'
import Link from './Link'

const ProjectItem = ({ project, sizeClass, titleColored, withTitle }) => {
  return (
    <div
      className={`${sizeClass ? sizeClass : 'col-md-4'} col-sm-12 col-xs-12`}
    >
      <div className="card text-left text">
        <Link
          to={`/projects/${project.slug}/`}
          title={`Click to show ${project.title}`}
          className="card-img-top"
          style={{
            backgroundImage: `url(${project.thumbnail.childImageSharp.fluid.src})`,
            borderBottomColor: `${project.background_color}`,
          }}
        >
          <div
            className="project-lead-bg"
            style={{ backgroundColor: `${project.background_color}` }}
          />
          <div
            className="project-lead"
            style={{ color: `${project.lead_color}` }}
          >
            {project.description}
          </div>
        </Link>
        <div className="card-body">
          {withTitle ? (
            <p className="text-muted mb-0 text-sm text-uppercase">
              {project.title}
            </p>
          ) : null}
          <h4
            className="project-title"
            style={titleColored ? { color: project.background_color } : null}
          >
            <Link
              to={`/projects/${project.slug}/`}
              title={`Click to show ${project.title}`}
              className="link-unstyled"
            >
              {project.headline}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
