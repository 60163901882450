import React from 'react'
import ProjectItem from '../common/ProjectItem'

const ProjectsContainer = ({ listOfProjectNodes }) => {
  const renderProjects = projects => {
    let magicFactor = 0
    const projectsArrLength = listOfProjectNodes.length;
    return projects.map((project, index) => {
      magicFactor += index
      return (
        <ProjectItem
          withTitle
          titleColored
          key={project.node.title}
          project={project.node}
          sizeClass={
            magicFactor % 2 === 0 ? (
              index % 2 === 0 && index === projectsArrLength - 1 ?
                'col-md-12' :
                'col-md-7') : (
                index % 2 !== 0 || index !== projectsArrLength - 1 ?
                  'col-md-5' :
                  'col-md-12'
              )
          }
        />
      )
    })
  }

  return (
    <section id="selected-projects" className="projects-list">
      <div className="container">
        <div className="row">{renderProjects(listOfProjectNodes)}</div>
      </div>
    </section>
  )
}

export default ProjectsContainer
